import React, { Component } from 'react'
import logo from '../logo/ryuk_labs.jpeg';


export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      twitter_id: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, twitter_id } = this.state;
    console.log(email, twitter_id);


    fetch("https://rlrefferalcode.herokuapp.com/register", {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        email,
        twitter_id
      }),
    }).then((res) => res.json())
      .then((data) => {
        console.log(data, "user");


        if (data.status === "ok") {

          window.location.href = `./userDetails?code=${data.referral_code}`;
        }
        else if (data.error === "User Exists") {
          alert("Email Id Already Exists! Try a Different One!");
        }
        else {
          alert("Something went wrong! Try Again");
        }

      })
  }


  render() {
    return (
      <div className="App">
        <form onSubmit={this.handleSubmit} className="loginContainer">
          <h4 class="logo">Generate Your Referral Code</h4>
          <img src={logo} alt="Logo" width="130" height="130" class="logo" />
          <div className="input-container">
            <label>Email </label>
            <input type="email"
              onChange={(e) => this.setState({ email: e.target.value })}
              required
            />
            {/* {renderErrorMessage("uname")} */}
          </div>
          <div className="input-container">
            <label>Twitter ID </label>
            <input
              type="text"
              onChange={(e) => this.setState({ twitter_id: e.target.value })}
              required
            />
            {/* {renderErrorMessage("pass")} */}
          </div>
          <button className="loginBut">
            <p>Generate</p>
          </button>
        </form>
      </div>
      // <form onSubmit={this.handleSubmit} className="card">

    )
  }
}
