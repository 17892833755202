import React from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from '../logo/ryuk_labs.jpeg';


export default function UserDetails() {
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('code');

  return (
    <div className="App">
      <form className="loginContainer">
        <img src={logo} alt="Logo" width="130" height="130" class="logo" />
        <div className="input-container">
          <h3 class="center">Success! Your Referral Code is </h3>
          <div className="referral-code">
            {referralCode}
          </div>
        </div>
      </form>
    </div>
  );
}
