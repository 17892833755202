import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/login.component'
import UserDetails from './components/userDetails'


function App() {
  return (
    <div className="page">
    <Router>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route path="/sign-in" element={<Login />} />
              <Route path="/userDetails" element={<UserDetails />} />
            </Routes>
    </Router>
    </div>
  )
}

export default App
